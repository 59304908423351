import React from "react"
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'

export default ({ data }) => (
  <Layout>
    <SEO />
    <main className="l-content">
      <div className="p-content">
        <section className="p-mv">
          <div className="p-img-wrap">
            <Img fluid={data.mv01.childImageSharp.fluid} alt="メインビジュアル画像" className="u-sp" />
            <Img fluid={data.mv02.childImageSharp.fluid} alt="メインビジュアル画像" className="u-pc" />

            <div className="p-mv__texts">
              <h2 className="p-mv__hdg">
                <span className="hdg__item">中小企業から</span><br/>
                <span className="hdg__item">採用活動を無くす</span>
              </h2>
              <p className="p-mv__sub">あなたはあなたのやるべきことを</p>
            </div>
          </div>

          <p className="m-btn">
            <Link to={'/contact/'}>採用サイト制作を問い合わせる</Link>
          </p>
        </section>{/* p-mv */}

        <section className="p-solutions p-section">
          <div className="p-solutions__head">
            <h2 className="m-hdg">solutions</h2>
            <h3 className="m-hdg-sub">こんなお悩みを解決できます</h3>
            <p className="m-hdg-desc">
              中小企業の採用につきものの<br />
              「カネがない」「人手がかけられない」<br className="u-sp"/>
              「ノウハウがない」を解決します。
            </p>
          </div>
          <div className="p-solutions__body">
            <ul className="p-solutions__list">
              <li className="p-solutions__item">
                <h4 className="m-hdg__em">採用に金をかけられない</h4>
                <p className="m-hdg__pra">自社採用サイトを充実させることで、求人媒体頼りの採用手法から脱却し、トータルコストを下げることができます。</p>
              </li>
              <li className="p-solutions__item">
                <h4 className="m-hdg__em">採用してもすぐに辞められてしまう</h4>
                <p className="m-hdg__pra">採用前に応募者が知りたい情報を正しく提示していないことが原因です。<br/>「応募者が知りたい情報」を提供することで回避できます。</p>
              </li>
              <li className="p-solutions__item">
                <h4 className="m-hdg__em">ノウハウがない</h4>
                <p className="m-hdg__pra">採用サイトが出来上がれば、あとはコンテンツを追加していくだけ。サイト運用の手順など、必要なノウハウをご提供いたします。</p>
              </li>
              <li className="p-solutions__item">
                <h4 className="m-hdg__em">会社の知名度がない</h4>
                <p className="m-hdg__pra">SNSやブログなどの主体的な情報発信により、解決できます。<br/>メディアの運用の方法もご提供いたします。</p>
              </li>
            </ul>

            <p className="m-btn">
              <Link to={'/solutions/'}>解決できる課題をもっと見る</Link>
            </p>
          </div>
        </section>{/* p-solutions */}

        <section className="p-reasons">
          <div className="p-section">

            <div className="p-reasons__head">
              <h2 className="m-hdg">reasons</h2>
              <h3 className="m-hdg-sub">成果が上がる理由</h3>
            </div>

            <div className="p-reasons__body">
              <ul className="p-reasons__list">
                <li className="p-reasons__item">
                  <h4 className="m-hdg__em m-hdg__em--center">採用だけなく、入社後の<br/>定着率向上も実現</h4>
                  <p className="m-hdg__pra">「こんなはずじゃなかった」<br/>と入社早々に退職されることは最も避けるべき事態です。<br/>正しい情報設計によりミスマッチをなくし、定着率向上を実現します。</p>
                </li>
                <li className="p-reasons__item">
                  <h4 className="m-hdg__em m-hdg__em--center">
                  採用活動をきっかけとした<br/>「働きやすい会社」への変化
                  </h4>
                  <p className="m-hdg__pra">
                    「従業員のことを考えてくれている」<br/>
                    と言ってもらえる制度、フレックス制度、時差出勤、福利厚生などの「福利厚生」の着手は出来ていますか？<br/>
                    採用活動を契機に社内制度改革を提案します。
                  </p>
                </li>
                <li className="p-reasons__item">
                  <h4 className="m-hdg__em m-hdg__em--center">
                  情報発信が楽に楽しくできる<br/>
                  ノウハウを提供
                  </h4>
                  <p className="m-hdg__pra">
                  「その情報が欲しかった」<br/>
                  応募者は「鮮度」の高い情報を求めています。<br/>
                  「鮮度の高い情報発信」にブログは有効です。<br/>
                  どんな内容の投稿が効果的なのか、データ分析をもとにお伝えします。
                  </p>
                </li>
              </ul>

              <p className="m-btn">
                <Link to={'/reasons/'}>成果が上がる理由をもっと見る</Link>
              </p>
            </div>

          </div>
        </section>{/* p-reasons */}

        {/*
        <section className="p-case p-section">
          <div className="p-case__head">
            <h2 className="m-hdg">case</h2>
            <h3 className="m-hdg-sub">実績をご紹介します</h3>
          </div>

          <div className="p-case__body">
            <ul className="p-case__list">
              <li className="p-case__item">
                <Link to={'/case/aaa/'}>
                  <div className="p-case__img">
                    <img src="" alt="" />
                  </div>
                  <div className="p-case__name">〇〇株式会社</div>
                </Link>
              </li>
            </ul>

            <p className="m-btn">
              <Link to={'/case/'}>実績をもっと見る</Link>
            </p>
          </div>
        </section>
        */}

        <section className="p-youtube">
          <div className="p-youtube__img-wrap">
            <Img fluid={data.yt01.childImageSharp.fluid} alt="Youtubeチャンネルへの導線" style={{ height: "100%" }} />
          </div>
          <div className="p-youtube__inner">
            <div className="p-youtube__head">
              <h2 className="m-hdg">youtube</h2>
              <h3 className="m-hdg-sub">役立つ情報を動画で配信しています</h3>
              <p className="m-btn">
                <Link to={'https://www.youtube.com/channel/UCzPtw0y7q4Ol_3KZKbLVvJg'}>Youtubeチャンネルを見る</Link>
              </p>
            </div>
          </div>
        </section>{/* p-youtube */}

      </div>{/* p-content */}
    </main>
  </Layout>
)

export const query = graphql`
  query {
    mv01: file(relativePath: {eq: "mv_sp01.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    mv02: file(relativePath: {eq: "mv_pc01.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    yt01: file(relativePath: {eq: "img_yt01.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`